export const ROWS_COUNT = 6;
export const COLUMNS_COUNT = 12;

const INITIAL_BEAD_ROAD_STATE = {
  items: [],
  rows: ROWS_COUNT,
  columns: COLUMNS_COUNT,
  count: 0,
};

const INITIAL_BIG_ROAD_STATE = {
  items: [],
  rows: ROWS_COUNT,
  columns: COLUMNS_COUNT,
  count: 0,
};

const INITIAL_BIG_EYE_ROAD_STATE = {
  items: [],
  rows: ROWS_COUNT,
  columns: COLUMNS_COUNT,
  count: 0,
};

const INITIAL_SMALL_ROAD_STATE = {
  items: [],
  rows: ROWS_COUNT,
  columns: COLUMNS_COUNT,
  count: 0,
};
const INITIAL_COCKROACH_ROAD_STATE = {
  items: [],
  rows: ROWS_COUNT,
  columns: COLUMNS_COUNT,
  count: 0,
};

export const INITIAL_STATE = {
  bead: INITIAL_BEAD_ROAD_STATE,
  big: INITIAL_BIG_ROAD_STATE,
  bigEye: INITIAL_BIG_EYE_ROAD_STATE,
  small: INITIAL_SMALL_ROAD_STATE,
  cockroach: INITIAL_COCKROACH_ROAD_STATE,
  results: [],
};
