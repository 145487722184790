export * from './colors';
export * from './strings';
export { default as BET_TYPES, } from './betTypes';
export {
  PAYOUTS,
  SUPER_SIX_PAYOUTS,
  DRAGON_BONUS_PAYOUTS,
  KNOCKOUT_PAYOUTS,
  DRAGON_TIGER_PAYOUTS,
  NO_COMISSION_PAYOUTS,
} from './payouts';
