import { of, } from 'rxjs';
import { map, omit, } from 'ramda';
import { LOADING, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';

import { PLAYER_HAND, BANKER_HAND, } from '../../../constants/strings';
import cardsActions from '../../actions/cards';

import { isKnockoutBaccaratSelector, } from '../../selectors/game';
import { WELCOME_TO_KNOCKOUT_DIALOG, NO_KNOCKOUT_DIALOG, } from '../../../dialogs/constants';

const {
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  settingsActions: { settings, },
  betActions: { bet, },
  dialogActions: { dialog, },
} = actions;

export default function handleInitialData(socketMessage, state) {
  const { VideoStreamData, roundId, timeStamp, RoundTripStartTime, data, } = socketMessage;
  const {
    History,
    ColdNumbers,
    HotNumbers,
    BankerCards,
    PlayerCards,
    playerBetOfThisRound,
    bankerHandValue,
    playerHandValue,
  } = data;
  const [ , noKnockoutDialog, ] = ls.get(NO_KNOCKOUT_DIALOG);
  const showDialog = isKnockoutBaccaratSelector(state) && !noKnockoutDialog;
  return of(
    socket.success(socketMessage),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
    }),
    game.set({
      gameResults: History,
      coldNumbers: ColdNumbers,
      hotNumbers: HotNumbers,
    }),
    dialog.add({ name: showDialog ? WELCOME_TO_KNOCKOUT_DIALOG : '', }),
    bet.apply({
      current: map((value) => ({ value, valid: true, }), {
        ...omit('SideBets', playerBetOfThisRound),
        ...playerBetOfThisRound.SideBets,
        ...(playerBetOfThisRound.SideBets || {}).WinningTotals,
      }),
    }),
    cardsActions.cards.set({
      [BANKER_HAND]: { cards: BankerCards, totalValue: bankerHandValue, },
      [PLAYER_HAND]: { cards: PlayerCards, totalValue: playerHandValue, },
    })
  );
}
