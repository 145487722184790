import update from 'immutability-helper';
import { ROWS_COUNT, } from '../../../constants/roads';
import { TIE, } from '../../../constants/strings';

import {
  alwaysZero, addOne, bimap, clip, identity, isEmptySpot, createRowsMatrix,
} from './helpers';

const bigRoadCoordinatesMap = {
  Init: [ alwaysZero, alwaysZero, ],
  'Player-Player': [ addOne, identity, ],
  'Player-Banker': [ alwaysZero, identity, ], // don't increase the column count - mapBigItemCoordinates will
  'Banker-Banker': [ addOne, identity, ],
  'Banker-Player': [ alwaysZero, identity, ], // don't increase the column count - mapBigItemCoordinates will
  'Banker-Tie': [ addOne, identity, ],
  'Player-Tie': [ addOne, identity, ],
  'Tie-Banker': [ identity, identity, ],
  'Tie-Player': [ identity, identity, ],
};

export function getBigRowsAndColumns({ columns, items, firstEmptyColumn = 0, }, isPop) {
  const [ prev, ] = items.slice(-1);
  const [ , c, ] = prev.coordinates;

  if (!isPop) items[items.length - 1].addsColumn = c === columns - 1;
  if (c === columns - 1) {
    columns += 1;
  }

  return {
    rows: ROWS_COUNT,
    columns,
    firstEmptyColumn: c < firstEmptyColumn ? firstEmptyColumn : c + 1,
    count: items.length,
  };
}

export function getBigItemCoordinates(prev) {
  if (prev == null) return [ 0, 0, ];
  const [ r, c, ] = prev;

  const nextCoords = +r < 5 ? [ +r + 1, +c, ] : [ 0, +c + 1, ];
  return nextCoords;
}

function findNextEmptySpot([ r, c, ], rowsMatrix) {
  if (!rowsMatrix[r][c + 1] || isEmptySpot([ r, c + 1, ], rowsMatrix)) {
    return [ r, c + 1, ];
  }
  return findNextEmptySpot([ r, c + 1, ], rowsMatrix);
}

function findFirstEmptySpot([ r, c, ], rowsMatrix) {
  if (c === 0) return [ r, c, ];

  if (!rowsMatrix[r][c - 1]) {
    return findFirstEmptySpot([ r, c - 1, ], rowsMatrix);
  }
  return [ r, c, ];
}

export function mapBigItemCoordinates(item, temp, rows, rowsMatrix) {
  const [ _r, c, ] = temp;
  const r = clip(_r, rows - 1);

  if (!rowsMatrix[r][c] || isEmptySpot([ r, c, ], rowsMatrix)) {
    if (r === 0 && c > 0) {
      item.coordinates = findFirstEmptySpot([ r, c, ], rowsMatrix);
    } else {
      item.coordinates = [ r, c, ];
    }
  } else if (_r >= rows) {
    item.coordinates = findNextEmptySpot([ r, c, ], rowsMatrix);
  } else {
    item.coordinates = findNextEmptySpot([ r - 1 < 0 ? r : r - 1, c, ], rowsMatrix);
  }
}

export function plotBigItems(acc, { ...item }) {
  const items = [ ...acc.items, ];
  const prev = items.pop();
  let rowsMatrix = {
    ...(acc.rowsMatrix || createRowsMatrix(acc.rows, acc.columns)),
  };

  let columnsMatrix = [ ...(acc.columnsMatrix || []), ];

  if (prev) {
    if (item.WinningHand === TIE) {
      item = {
        ...item,
        ...prev,
        tieCount: (prev.tieCount || 0) + 1,
      };
      items.push(item);
    } else {
      const first = prev.WinningHand === TIE ? prev.color || TIE : prev.WinningHand;
      const key = `${first}-${item.WinningHand}`;
      mapBigItemCoordinates(item, bimap(bigRoadCoordinatesMap[key])(prev.coordinates), acc.rows, rowsMatrix);
      items.push(prev);
      items.push(item);

      if (item.WinningHand !== prev.WinningHand) {
        columnsMatrix = [ ...columnsMatrix, [ { ...item, derived: {}, }, ], ];
      } else if (columnsMatrix[columnsMatrix.length - 1]) {
        columnsMatrix = update(columnsMatrix, {
          [columnsMatrix.length - 1]: {
            $push: [
              {
                ...item,
                derived: {},
              },
            ],
          },
        });
      }
    }
  } else {
    item.coordinates = [ 0, 0, ];
    if (item.WinningHand === TIE) {
      item.tieCount = 1;
      item.color = TIE;
      columnsMatrix = [ ...columnsMatrix, [], ];
    } else {
      columnsMatrix = [ ...columnsMatrix, [ item, ], ];
    }

    items.push(item);
  }

  const [ r, c, ] = item.coordinates;
  rowsMatrix = { ...rowsMatrix, [r]: { ...rowsMatrix[r], [c]: { ...item, }, }, };

  return {
    ...acc,
    items,
    rowsMatrix,
    columnsMatrix,
    ...getBigRowsAndColumns({ ...acc, rowsMatrix, items, }, false),
    latest: { ...item, },
  };
}
