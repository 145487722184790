import {
  roadsEpic,
  socketEpic,
  betsEpic,
  roadsReducer,
  betsReducer,
  cardsReducer,
  cardsEpic,
  uiConfigEpic,
  analyticsEpic,
} from './store/index';
import { WELCOME_TO_KNOCKOUT_DIALOG, } from './dialogs/constants';

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard/GameBoard'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Payouts: () => import('./components/Payouts'),
    Statistics: () => import('./components/Statistics'),
    MiniLobbyWidget: () => import('./components/SvgRoadComponent'),
    [WELCOME_TO_KNOCKOUT_DIALOG]: () => import('./dialogs/WelcomeToKnockout'),
    ProgressBar: () => Promise.resolve({ default: () => null, }),
    VideoOverlay: () => import('./components/VideoOverlay'),
  },
  store: {
    epics: [ roadsEpic, socketEpic, betsEpic, cardsEpic, uiConfigEpic, analyticsEpic, ],
    reducers: {
      roads: roadsReducer,
      bets: betsReducer,
      cards: cardsReducer,
    },
  },
  props: {
    BetToolbar: {
      style: {
        zIndex: 1,
      },
    },
  },
  gameBoardHeight: 22,
  desktopBoardToggle: true,
  landscapeToolbarOrientation: 'vertical',
  hideLeftSideDrawer: true,
});
