import { createSelector, } from 'reselect';
import { selectors, } from '@ezugi/bootstrap';

import { getPreviews, } from '../utils/roads';
import { baccaratConfigSelector, } from './config';

const { gameIdSelector, } = selectors;

const selectRoads = (state) => state.roads;

export const noBgConfigSelector = createSelector(
  baccaratConfigSelector,
  (config) => config.noStatsOrRoadmapsBackground
);

export const roadsSelector = createSelector(
  selectRoads,
  (roads) => roads
);

export const roadsPreviewsSelector = createSelector(
  roadsSelector,
  gameIdSelector,
  getPreviews
);

export const beadRoadSelector = createSelector(
  roadsSelector,
  (roads) => roads.bead
);

export const bigRoadSelector = createSelector(
  roadsSelector,
  (roads) => roads.big
);

export const bigEyeRoadSelector = createSelector(
  roadsSelector,
  (roads) => roads.bigEye
);

export const smallRoadSelector = createSelector(
  roadsSelector,
  (roads) => roads.small
);

export const cockroachRoadSelector = createSelector(
  roadsSelector,
  (roads) => roads.cockroach
);

export const resultsSelector = createSelector(
  roadsSelector,
  (roads) => roads.results || []
);

export const statsSelector = createSelector(
  resultsSelector,
  (results) => results.reduce(
    (acc, r) => {
      acc[r.WinningHand] += 1;
      acc.playerPairs += r.isPlayerPair;
      acc.bankerPairs += r.isBankerPair;
      acc.naturalHands += !!r.isNaturalHand;

      return acc;
    },
    { count: results.length, Player: 0, Banker: 0, Tie: 0, playerPairs: 0, bankerPairs: 0, naturalHands: 0, }
  )
);
