import { ofType, combineEpics, } from 'redux-observable';
import {
  pluck, filter, flatMap, tap, ignoreElements, take, switchMap,
} from 'rxjs/operators';
import { actions, socket$, } from '@ezugi/bootstrap';
import {
  INITIAL_DATA, GAME_RESULT, CARD, CANCEL_LAST_CARD,
} from '@ezugi/constants';
import { Card, } from '@ezugi/primitives';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleCancelLastCard from './handleCancelLastCard';

const {
  socketActions: { socket, },
  videoActions: { video, },
} = actions;
function initialDataEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap((socketMessage) => handleInitialData(socketMessage, state$.value))
  );
}

function gameResultEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap(handleGameResult)
  );
}

function cardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD),
    flatMap((socketMessage) => handleCardMessage(socketMessage, state$.value))
  );
}

function cancelLastCardEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_LAST_CARD),
    flatMap((socketMessage) => handleCancelLastCard(socketMessage, state$.value))
  );
}

function preloadCardEpic(action$) {
  return action$.pipe(
    ofType(video.set),
    take(1),
    pluck('payload', 'cdnList', 'videoDelay'),
    switchMap((delay) => (+delay
      ? socket$.pipe(
        filter(({ MessageType, }) => MessageType === CARD),
        pluck('CardName'),
        tap(Card.preload)
      )
      : socket$.pipe(
        take(1),
        tap(() => {
          Card.preloadAll();
        })
      ))),
    ignoreElements()
  );
}

export default combineEpics(initialDataEpic, gameResultEpic, cardMessageEpic, preloadCardEpic, cancelLastCardEpic);
