import { createSelector, } from 'reselect';

const selectGame = (state) => state.game;

export const lastGameResultSelector = createSelector(
  selectGame,
  (game) => game.gameResults
);

export const isKnockoutBaccaratSelector = createSelector(
  selectGame,
  (game) => Number(game.gameId) === 20
);

export const isDragonTigerSelector = createSelector(
  selectGame,
  (game) => Number(game.gameId) === 24
);
