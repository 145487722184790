import { pluck, filter, flatMap, } from 'rxjs/operators';
import { of, } from 'rxjs';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, } from '@ezugi/constants';
import cardsActions from '../../actions/cards';

const {
  roundActions: { round, },
} = bootstrapActions;

const cardsResetEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS),
  flatMap(() => of(cardsActions.cards.reset()))
);

export default cardsResetEpic;
