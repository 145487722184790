export const BEAD = 'bead';
export const BIG = 'big';
export const BIG_EYE = 'bigEye';
export const SMALL = 'small';
export const COCKROACH = 'cockroach';
export const PLAYER = 'Player';
export const BANKER = 'Banker';
export const TIE = 'Tie';
export const PLAYER_HAND = 'player';
export const BANKER_HAND = 'banker';
export const SIDE_BETS = 'sideBets';
export const NATURAL_WIN = 'naturalWin';
export const NATURAL_TIE = 'naturalTie';
export const DRAGON = 'Dragon';
export const TIGER = 'Tiger';

/* SuperSix */
export const BANKER_WINS_WITH_SIX = 'bankerWinsWithSix';

/* DragonBonus */
export const NON_NATURAL_WIN_BY_NINE_POINTS = 'nonNaturalWinBy9Points';
export const NON_NATURAL_WIN_BY_EIGHT_POINTS = 'nonNaturalWinBy8Points';
export const NON_NATURAL_WIN_BY_SIX_SEVEN_POINTS = 'nonNaturalWinBy67Points';
export const NON_NATURAL_WIN_BY_FOUR_FIVE_POINTS = 'nonNaturalWinBy45Points';
