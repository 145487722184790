import { INITIAL_STATE, } from '../../../constants/roads';
import {
  BEAD, BIG, BIG_EYE, SMALL, COCKROACH, PLAYER, BANKER,
} from '../../../constants/strings';

import { plotBeadItems, } from './beadRoad';
import { plotBigItems, } from './bigRoad';
import { plotDerivedItems, } from './derivedRoad';

export * from './helpers';
export * from './bigRoad';
export * from './beadRoad';

export function getRoads(items, prevState = INITIAL_STATE) {
  const { preview, ...state } = prevState;

  const computed = items.reduce(
    (acc, r) => {
      if (typeof r === 'undefined') return acc;

      const bead = plotBeadItems(acc.bead, r);
      const big = plotBigItems(acc.big, r);

      const bigEye = plotDerivedItems({
        startFrom: [ 1, 1, ],
        skip: 0,
        columnsMatrix: big.columnsMatrix,
        name: BIG_EYE,
      })(acc.bigEye, r);

      const small = plotDerivedItems({
        startFrom: [ 1, 2, ],
        skip: 1,
        columnsMatrix: big.columnsMatrix,
        name: SMALL,
      })(acc.small, r);

      const cockroach = plotDerivedItems({
        startFrom: [ 1, 3, ],
        skip: 2,
        columnsMatrix: big.columnsMatrix,
        name: COCKROACH,
      })(acc.cockroach, r);

      return {
        ...acc,
        bead,
        big,
        bigEye,
        small,
        cockroach,
      };
    },
    {
      ...state,
    }
  );
  return computed;
}

function getPlayerPreview(gameId) {
  return {
    WinningHand: PLAYER,
    CardHandValue: Number(gameId) === 24 ? 'D' : 'P',
    roundId: 'preview',
  };
}

function getBankerPreview(gameId) {
  return {
    WinningHand: BANKER,
    CardHandValue: Number(gameId) === 24 ? 'T' : 'B',
    roundId: 'preview',
  };
}

export function getPreviews(roads, gameId) {
  const player = getRoads([ getPlayerPreview(gameId), ], roads);
  const banker = getRoads([ getBankerPreview(gameId), ], roads);

  return {
    Player: {
      [BEAD]: player.bead.latest,
      [BIG]: player.big.latest,
      [BIG_EYE]: player.bigEye.latest,
      [SMALL]: player.small.latest,
      [COCKROACH]: player.cockroach.latest,
    },
    Banker: {
      [BEAD]: banker.bead.latest,
      [BIG]: banker.big.latest,
      [BIG_EYE]: banker.bigEye.latest,
      [SMALL]: banker.small.latest,
      [COCKROACH]: banker.cockroach.latest,
    },
  };
}

export function previewItem(item) {
  return getRoads(item);
}
