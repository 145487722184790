import { createSelector, } from 'reselect';
import { propOr, prop, } from 'ramda';
import { PLAYER_HAND, BANKER_HAND, } from '../../constants/strings';

export const cardsSelector = prop('cards');

export const playerCardsSelector = createSelector(
  cardsSelector,
  propOr({}, PLAYER_HAND)
);

export const bankerCardsSelector = createSelector(
  cardsSelector,
  propOr({}, BANKER_HAND)
);
