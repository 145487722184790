import * as BET_TYPES from './betTypes';
import {
  BANKER_WINS_WITH_SIX,
  NATURAL_WIN,
  NATURAL_TIE,
  NON_NATURAL_WIN_BY_NINE_POINTS,
  NON_NATURAL_WIN_BY_EIGHT_POINTS,
  NON_NATURAL_WIN_BY_SIX_SEVEN_POINTS,
  NON_NATURAL_WIN_BY_FOUR_FIVE_POINTS,
} from './strings';

export const PAYOUTS = {
  [BET_TYPES.PLAYER]: 1,
  [BET_TYPES.TIE]: 8,
  [BET_TYPES.BANKER]: 0.95,
  [BET_TYPES.PLAYER_PAIR]: 11,
  [BET_TYPES.BANKER_PAIR]: 11,
  [BET_TYPES.PERFECT_PAIR]: 25,
  [BET_TYPES.EITHER_PAIR]: 5,
  [BET_TYPES.SMALL]: 1.5,
  [BET_TYPES.BIG]: 0.54,
};

export const SUPER_SIX_PAYOUTS = {
  [BET_TYPES.PLAYER]: 1,
  [BET_TYPES.TIE]: 8,
  [BET_TYPES.BANKER]: 1,
  [BET_TYPES.SUPER_SIX]: 12,
  [BANKER_WINS_WITH_SIX]: 0.5,
};

export const DRAGON_BONUS_PAYOUTS = {
  [BET_TYPES.PLAYER]: 1,
  [BET_TYPES.TIE]: 8,
  [BET_TYPES.BANKER]: 0.95,
  [NATURAL_WIN]: 1,
  // 0 value indicates Push
  [NATURAL_TIE]: 0,
  [NON_NATURAL_WIN_BY_NINE_POINTS]: 30,
  [NON_NATURAL_WIN_BY_EIGHT_POINTS]: 10,
  [NON_NATURAL_WIN_BY_SIX_SEVEN_POINTS]: 4,
  [NON_NATURAL_WIN_BY_FOUR_FIVE_POINTS]: 2,
};

export const KNOCKOUT_PAYOUTS = {
  [BET_TYPES.PLAYER]: 1,
  [BET_TYPES.TIE]: 8,
  [BET_TYPES.BANKER]: 0.95,
  [NATURAL_TIE]: 0,
  [NATURAL_WIN]: 4,
  [BET_TYPES.NATURAL_PLAYER]: 4,
  [BET_TYPES.NATURAL_BANKER]: 4,
  [BET_TYPES.TOTALS_1_TO_4]: 7.5,
  [BET_TYPES.TOTALS_5_TO_6]: 4,
  [BET_TYPES.TOTALS_7]: 4.5,
  [BET_TYPES.TOTALS_8]: 3,
  [BET_TYPES.TOTALS_9]: 2.5,
};

export const DRAGON_TIGER_PAYOUTS = {
  [BET_TYPES.DRAGON]: 1,
  [BET_TYPES.TIGER]: 1,
  [BET_TYPES.TIE]: 8,
};

export const NO_COMISSION_PAYOUTS = {
  ...PAYOUTS,
  [BET_TYPES.BANKER]: 1,
  [BANKER_WINS_WITH_SIX]: 0.5,
};
