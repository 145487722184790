import { ROWS_COUNT, COLUMNS_COUNT, } from '../../../constants/roads';

export function getBeadRowsAndColumns(acc) {
  const count = acc.items.length;
  const rows = ROWS_COUNT;
  let columns = Math.ceil(count / ROWS_COUNT);
  columns = columns < COLUMNS_COUNT ? COLUMNS_COUNT : columns;

  if (columns > COLUMNS_COUNT) {
    const diff = columns * rows - count;
    if (diff < 6) columns += 1;
  }

  return {
    rows,
    columns,
    count,
    firstEmptyColumn: Math.ceil(count / ROWS_COUNT),
  };
}

export function getBeadItemCoordinates(prev) {
  if (prev == null) return [ 0, 0, ];
  const [ r, c, ] = prev;

  const nextCoords = +r < 5 ? [ +r + 1, +c, ] : [ 0, +c + 1, ];
  return nextCoords;
}

export function mapBeadItemCoordinates(acc, item) {
  const prevItem = acc.items[acc.items.length - 1];

  return {
    ...item,
    coordinates: getBeadItemCoordinates((prevItem || {}).coordinates),
  };
}

export function plotBeadItems(acc, { ..._item }) {
  const items = [ ...acc.items, mapBeadItemCoordinates(acc, _item), ];
  const item = mapBeadItemCoordinates(acc, _item);

  return {
    ...acc,
    items: [ ...acc.items, item, ],
    ...getBeadRowsAndColumns({ ...acc, items, }),
    latest: item,
  };
}
