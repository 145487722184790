/* eslint-disable consistent-return */
import { plotBigItems, } from './bigRoad';
import { PLAYER, BANKER, } from '../../../constants/strings';

function canPush([ r, c, ], columnsMatrix) {
  return columnsMatrix.length > c + 1 || (columnsMatrix[c] && columnsMatrix[c].length > r);
}

function mapItem(startFrom, skip, matrix, name) {
  const [ curr, ] = matrix.slice(-1);

  if (curr.length === 1) {
    const item = curr[0];
    if (item.derived[name]) return;

    const cols = matrix.slice(-(skip + 3));
    if (cols.length < skip + 3) cols.unshift([]);

    const [ a, ] = cols;
    const [ b, ] = cols.slice(-2);

    // mark as derived to ignore next time
    item.derived[name] = true;

    return a.length === b.length
      ? { roundId: item.roundId, WinningHand: BANKER, }
      : { roundId: item.roundId, WinningHand: PLAYER, };
  }
  const [ prev, ] = matrix.slice(-(skip + 2));
  const row = curr.length - 1;

  const item = curr[row];
  if (item.derived[name]) return;

  // mark as derived to ignore next time
  item.derived[name] = true;

  return (prev[row] || {}).WinningHand === (prev[row - 1] || {}).WinningHand
    ? { roundId: item.roundId, WinningHand: BANKER, }
    : { roundId: item.roundId, WinningHand: PLAYER, };
}

export function plotDerivedItems({ startFrom = [ 1, 1, ], skip = 0, columnsMatrix, name, }) {
  return (acc) => {
    if (canPush(startFrom, columnsMatrix)) {
      const item = mapItem(startFrom, skip, columnsMatrix, name);
      if (!item) return acc;

      const { columnsMatrix: cm, ...big } = plotBigItems(acc, item);
      return {
        ...acc,
        ...big,
      };
    }

    return {
      ...acc,
      items: [],
    };
  };
}
