import { combineEpics, } from 'redux-observable';
import { fromEvent, interval, } from 'rxjs';
import { filter, map, throttle, } from 'rxjs/operators';
import { compose, not, isNil, } from 'ramda';

import { actions, selectors, } from '@ezugi/bootstrap';

import { BAC_ASK_PLAYER_BANKER, BAC_STATISTICS_CLICK, } from '../../constants/analytics';

const {
  analyticsActions: { analytics, },
} = actions;

const { roundIdSelector, tableIdSelector, } = selectors;

const isNotNil = compose(not, isNil);

/**
 * Send statistics click analytics event
 */
const statisticsClickEpic = (_, state$) => fromEvent(
  document,
  'click',
  // Target the statistics header
  // TODO: update <Switcher /> to allow for additional props
  (evt) => evt.target.closest('#left-header ul li:last-child')
).pipe(
  filter(isNotNil),
  throttle(() => interval(1000)),
  map(() => analytics.push({
    event: BAC_STATISTICS_CLICK,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
  }))
);

/**
 * Send ask player analytics event
 */
const askPlayerEpic = (_, state$) => fromEvent(document, 'click', (evt) => evt.target.closest('#ask-player')).pipe(
  filter(isNotNil),
  throttle(() => interval(1000)),
  map(() => analytics.push({
    event: BAC_ASK_PLAYER_BANKER,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
    value: 'P',
  }))
);

/**
 * Send ask banker analytics event
 */
const askBankerEpic = (_, state$) => fromEvent(document, 'click', (evt) => evt.target.closest('#ask-banker')).pipe(
  filter(isNotNil),
  throttle(() => interval(1000)),
  map(() => analytics.push({
    event: BAC_ASK_PLAYER_BANKER,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
    value: 'B',
  }))
);

export default combineEpics(statisticsClickEpic, askPlayerEpic, askBankerEpic);
