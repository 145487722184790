import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { playerCardsSelector, bankerCardsSelector, } from '../../selectors/cards';
import { PLAYER_HAND, } from '../../../constants/strings';

export default function handleCardMessage(socketMessage, state) {
  const { CardHand, CardHandValue, CardName, CardValue, } = socketMessage;

  const { cards, } = CardHand === PLAYER_HAND ? playerCardsSelector(state) : bankerCardsSelector(state);

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: {
          totalValue: CardHandValue,
          cards: [
            ...cards,
            {
              CardName,
              CardValue,
            },
          ],
        },
      })
    )
  );
}
