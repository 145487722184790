import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { cardsSelector, } from '../../selectors/cards';

export default function handleCancelLastCard(socketMessage, state) {
  const { CardHand, } = socketMessage;

  const { cards, } = cardsSelector(state)[CardHand];
  const nextCards = [ ...cards, ];
  nextCards.pop();
  const totalValue = nextCards.reduce((sum, { CardValue, }) => (sum + CardValue) % 10, 0);

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: {
          totalValue,
          cards: nextCards,
        },
      })
    )
  );
}
