import { of, } from 'rxjs';
import {
  first, pluck, tap, ignoreElements, catchError,
} from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { setUIConfigByGameId, } from '../../../uiConfig';

const {
  gameActions: { game, },
  tableActions: { table, },
} = bootstrapActions;

const uiConfigEpic = (action$) => action$.pipe(
  ofType(table.set),
  first(),
  pluck('payload'),
  tap((payload) => {
    const gameId = payload.game_id;

    setUIConfigByGameId(gameId);
  }),
  catchError((err) => of(game.failure(err))),
  ignoreElements()
);

export default uiConfigEpic;
