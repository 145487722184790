import { createSelector, } from 'reselect';

const selectConfig = (state) => state.config;

export const baccaratConfigSelector = createSelector(
  selectConfig,
  (config) => config.baccarat || {}
);

/**
 * Configuration for disabling small & big sidebets
 * DVC2M-3
 */
export const smallAndBigConfigSelector = createSelector(
  baccaratConfigSelector,
  (config) => config.disableSmallAndBig
);
