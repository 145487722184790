import { handleActions, } from 'redux-actions';

import { INITIAL_STATE, } from '../../../constants/roads';
import { roads, } from '../../actions/roads';

export default handleActions(
  {
    [roads.set.success]: (state, { payload, }) => ({ ...state, ...payload, }),
    [roads.reset]: () => INITIAL_STATE,
  },
  INITIAL_STATE
);
