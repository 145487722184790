import { BACCARAT_BET_TYPES, } from '@ezugi/constants';

export const {
  // newline
  PLAYER,
  TIE,
  BANKER,
  PLAYER_PAIR,
  BANKER_PAIR,
  PERFECT_PAIR,
  EITHER_PAIR,
  SMALL,
  BIG,
  SUPER_SIX,
  DRAGON_BONUS_PLAYER,
  DRAGON_BONUS_BANKER,
  NATURAL_PLAYER,
  NATURAL_BANKER,
  TOTALS_1_TO_4,
  TOTALS_5_TO_6,
  TOTALS_7,
  TOTALS_8,
  TOTALS_9,
  DRAGON,
  TIGER,
} = BACCARAT_BET_TYPES;

export const SIDEBETS = {
  PLAYER_PAIR,
  BANKER_PAIR,
  PERFECT_PAIR,
  EITHER_PAIR,
  SMALL,
  BIG,
  SUPER_SIX,
  DRAGON_BONUS_PLAYER,
  DRAGON_BONUS_BANKER,
  NATURAL_PLAYER,
  NATURAL_BANKER,
};

export const WINNING_TOTALS = {
  TOTALS_1_TO_4,
  TOTALS_5_TO_6,
  TOTALS_7,
  TOTALS_8,
  TOTALS_9,
};

export const MAIN_BETS = {
  PLAYER,
  TIE,
  BANKER,
  DRAGON,
  TIGER,
};

export default { ...BACCARAT_BET_TYPES, };
