export function createRowsMatrix(r) {
  const matrix = {};

  for (let i = 0; i < r; i++) {
    const row = [];
    matrix[i] = row;
  }
  return matrix;
}

export function createColumnsMatrix() {
  const matrix = [];
  return matrix;
}

export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

export function isEmptySpot([ r, c, ], matrix) {
  try {
    const empty = isEmptyObj(matrix[r][c]);
    return empty;
  } catch (e) {
    return false;
  }
}

export function clip(x, max) {
  return x > max ? max : x;
}

export function addOne(x) {
  return +x + 1;
}

export function identity(x) {
  return +x;
}

export function alwaysZero() {
  return 0;
}

export function bimap([ f, g, ]) {
  return ([ a, b, ]) => [ f(a), g(b), ];
}
