/* eslint-disable no-unused-vars, no-console */
import { of, concat, } from 'rxjs';
import {
  map, pluck, switchMap, take, flatMap, filter, tap, ignoreElements,
} from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';
import { actions, } from '@ezugi/bootstrap';
import { SHUFFLE, PLACE_YOUR_BETS, } from '@ezugi/constants';

import { roads, } from '../../actions/roads';
import { roadsSelector, } from '../../selectors/roads';
import { getRoads, } from '../../utils/roads';

const {
  socketActions: { socket, },
} = actions;
const socketEpic = (action$) => action$.pipe(
  ofType(socket.success),
  pluck('payload', 'data', 'History'),
  map(roads.set.request)
);

const resetEpic = (action$) => action$.pipe(
  ofType(socket.message),
  pluck('payload', 'MessageType'),
  filter((messageType) => messageType === SHUFFLE),
  switchMap(() => action$.pipe(
    ofType(socket.message),
    pluck('payload', 'MessageType'),
    filter((messageType) => messageType === PLACE_YOUR_BETS),
    take(1)
  )),
  map(roads.reset)
);

const setRequestEpic = (action$) => action$.pipe(
  ofType(roads.set.request),
  pluck('payload'),
  map((payload) => ({
    ...getRoads(payload),
    results: payload,
  })),
  map(roads.set.success)
);

const pushRequestEpic = (action$, state$) => action$.pipe(
  ofType(roads.push),
  pluck('payload'),
  switchMap((payload) => state$.pipe(
    map(roadsSelector),
    map((state) => ({
      payload,
      state,
    })),
    take(1)
  )),
  map(({ payload, state, }) => roads.set.success({
    ...getRoads([ payload, ], state),
    results: [ ...state.results, payload, ],
  }))
);

export default combineEpics(
  // newline
  socketEpic,
  setRequestEpic,
  pushRequestEpic,
  resetEpic
);
