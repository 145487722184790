import { createActions, } from 'redux-actions';

export const { roads, } = createActions({
  ROADS: {
    SET: {
      REQUEST: null,
      SUCCESS: null,
      FAILURE: null,
    },
    RESET: null,
    PUSH: null,
  },
});
