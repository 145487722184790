export const BANKER_COLOR = '#ce1822';
export const PLAYER_COLOR = '#1a5dc6';
export const TIE_COLOR = '#46a647';
export const NATURAL_COLOR = '#ffd400';
export const NEUTRAL_COLOR = '#9ca8c2';
export const ROAD_BACKGROUND_COLOR = '#fff';
export const ROAD_BORDER_COLOR = 'rgba(52, 56, 99, 0.2)';

export const BOARD_COLORS = {
  PLAYER: '#0098ff',
  BANKER: '#ff252f',
  TIE: '#30d832',
  SIDEBETS: '#9ca8c2',
  SUPER_SIX: '#c030d8',
  DRAGON: '#ff252f',
  TIGER: '#68d0df',
};
