import bowser from 'bowser';
import { mergeDeepRight, } from 'ramda';

import { MAIN_BETS, SIDEBETS, WINNING_TOTALS, } from './constants/betTypes';
import {
  PAYOUTS,
  SUPER_SIX_PAYOUTS,
  KNOCKOUT_PAYOUTS,
  DRAGON_BONUS_PAYOUTS,
  DRAGON_TIGER_PAYOUTS,
  NO_COMISSION_PAYOUTS,
  BOARD_COLORS,
  TIE_COLOR,
} from './constants';
import { desktopStandardGridComponent, desktopWideGridComponent, portraitGridComponent, } from './imports';

const isHandheld = bowser.mobile || bowser.tablet;

export const UI_CONFIG = {
  COMPONENTS: {
    GAMEBOARD: {
      DESKTOP: {
        COMPONENT: desktopStandardGridComponent,
      },
      PORTRAIT: {
        COMPONENT: portraitGridComponent,
      },
    },
  },
  MAIN_BETS: [ MAIN_BETS.PLAYER, MAIN_BETS.TIE, MAIN_BETS.BANKER, ],
  SIDE_BETS: [
    SIDEBETS.EITHER_PAIR,
    SIDEBETS.PERFECT_PAIR,
    SIDEBETS.BIG,
    SIDEBETS.SMALL,
    SIDEBETS.PLAYER_PAIR,
    SIDEBETS.BANKER_PAIR,
  ],
  COLORS: {
    PLAYER_COLOR: '#1a5dc6',
    BANKER_COLOR: '#ce1822',
    TIE_COLOR,
  },
  PAYOUTS,
  GAME_ID: 2,
  isKnockout() {
    return this.GAME_ID === 20;
  },
  isSuperSix() {
    return this.GAME_ID === 21;
  },
  isDragonTiger() {
    return this.GAME_ID === 24;
  },
  isNoComission() {
    return this.GAME_ID === 25;
  },
  isDragonBonus() {
    return this.GAME_ID === 26;
  },
};

export function setUIConfigByGameId(gameId) {
  const defaultConfig = UI_CONFIG;
  let configByGameId = {};

  switch (Number(gameId)) {
  case 20: {
    configByGameId = {
      COMPONENTS: {
        GAMEBOARD: {
          DESKTOP: { COMPONENT: desktopStandardGridComponent, },
          PORTRAIT: { COMPONENT: portraitGridComponent, },

        },
      },
      MAIN_BETS: [ MAIN_BETS.PLAYER, MAIN_BETS.TIE, MAIN_BETS.BANKER, ],
      SIDE_BETS: [
        SIDEBETS.NATURAL_PLAYER,
        SIDEBETS.NATURAL_BANKER,
        WINNING_TOTALS.TOTALS_1_TO_4,
        WINNING_TOTALS.TOTALS_5_TO_6,
        WINNING_TOTALS.TOTALS_7,
        WINNING_TOTALS.TOTALS_8,
        WINNING_TOTALS.TOTALS_9,
      ],
      PAYOUTS: KNOCKOUT_PAYOUTS,
      GAME_ID: 20,
    };
    break;
  }
  case 21: {
    configByGameId = {
      COMPONENTS: {
        GAMEBOARD: {
          DESKTOP: {
            COMPONENT: desktopWideGridComponent,
          },
          PORTRAIT: {
            COMPONENT: portraitGridComponent,
            PROPS: { viewBox: '0 0 365 140', },
          },
        },
      },
      MAIN_BETS: [ MAIN_BETS.PLAYER, MAIN_BETS.TIE, MAIN_BETS.BANKER, ],
      SIDE_BETS: [ SIDEBETS.SUPER_SIX, ],
      PAYOUTS: SUPER_SIX_PAYOUTS,
      GAME_ID: 21,
    };
    break;
  }
  case 24: {
    configByGameId = {
      COMPONENTS: {
        GAMEBOARD: {
          DESKTOP: {
            COMPONENT: desktopWideGridComponent,
            PROPS: {
              ...(!isHandheld && {
                style: { transform: 'translate(0, -15px)', },
              }),
            },
          },
          PORTRAIT: {
            COMPONENT: portraitGridComponent,
            PROPS: { viewBox: '0 0 365 140', },
          },
        },
      },
      MAIN_BETS: [ MAIN_BETS.DRAGON, MAIN_BETS.TIE, MAIN_BETS.TIGER, ],
      SIDE_BETS: [],
      COLORS: {
        PLAYER_COLOR: BOARD_COLORS.DRAGON,
        BANKER_COLOR: BOARD_COLORS.TIGER,
        TIE_COLOR,
      },
      PAYOUTS: DRAGON_TIGER_PAYOUTS,
      GAME_ID: 24,
    };
    break;
  }
  case 25: {
    configByGameId = {
      ...UI_CONFIG,
      PAYOUTS: NO_COMISSION_PAYOUTS,
      GAME_ID: 25,
    };
    break;
  }
  case 26: {
    configByGameId = {
      COMPONENTS: {
        GAMEBOARD: {
          DESKTOP: {
            COMPONENT: desktopStandardGridComponent,
            PROPS: {
              ...(!isHandheld && {
                style: { transform: 'translate(0, -15px)', },
              }),
            },
          },
          PORTRAIT: {
            COMPONENT: portraitGridComponent,
          },
        },
      },
      MAIN_BETS: [ MAIN_BETS.PLAYER, MAIN_BETS.TIE, MAIN_BETS.BANKER, ],
      SIDE_BETS: [ SIDEBETS.DRAGON_BONUS_PLAYER, SIDEBETS.DRAGON_BONUS_BANKER, ],
      PAYOUTS: DRAGON_BONUS_PAYOUTS,
      GAME_ID: 26,
    };
    break;
  }
  default:
    break;
  }

  Object.assign(UI_CONFIG, mergeDeepRight(defaultConfig, configByGameId));
}
